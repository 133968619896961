 <template>
	<div class="page">

		<!-- 搜索条件表单 -->
		<el-form class="ser_form" size="small"  label-width="68px">
			<el-form-item class="el_form_item" label="准驾车型">
				<el-input class="el_input" v-model="form.dl_class" clearable></el-input>
			</el-form-item>
			<el-form-item label-width="0">
				<el-button type="primary" @click="page_ser">查询</el-button>
				<el-button type="success" @click="open_add_view">添加</el-button>
			</el-form-item>
		</el-form>


		<!-- 表格 -->
		<div class="tab_height">
			<el-table :data="list.data" :border="true" :stripe="true" size="small" height="100%" v-loading="list.loading">
				<el-table-column label="添加时间" prop="creat_time_text"></el-table-column>
				<el-table-column label="准驾车型" prop="dl_class"></el-table-column>


				<!-- 操作行 -->
				<el-table-column label="操作" width="190">
					<template slot-scope="scope">
						<el-button @click="open_edit_view(scope.row)" size="mini" type="primary">修改</el-button>
						<el-button @click="del(scope.row)" size="mini" type="danger">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>

		<!-- 添加 -->
		<Add :is_show="add_form_is_show" @end_of_sub="close_add_view"></Add>
		
		<!-- 编辑 -->
		<Edit :is_show="edit_form_is_show" :driver_class="edit_data" @end_of_sub="close_edit_view"></Edit>
	</div>
</template>

<script>
	import Add from './add.vue'
	import Edit from './edit.vue'
	export default {
		components:{
			Add,
			Edit,
		},
		data() {
			return {

				//搜索条件
				form: {
					dl_class:'',//车型名称
				},

				//表格数据
				list: {
					loading: false,//loading特效
					data: [],//列表数据
					total: 0,//列表总记录数
				},

				//添加弹出层
				add_form_is_show:0,

				//编辑弹出层
				edit_form_is_show:0,
				edit_data:{},
			}
		},
		created() {

			//获取页面数据
			this.get_page_data()
		},
		methods: {

			//打开修改页面
			open_edit_view(item){

				//置入数据
				this.edit_data={...item}

				//打开弹出层
				this.edit_form_is_show++;
			},
			close_edit_view(){//关闭添加页面

				//关闭弹出层
				this.edit_form_is_show=0;

				//刷新数据
				this.get_page_data();
			},

			//删除操作
			del(item){

				//询问
				this.$my.other.confirm({
					content:"确认删除'"+item.dl_class+"'",
					confirm:()=>{

						//调用接口
						this.$my.net.req({
							data:{
								mod:'truck_driver',
								ctr:'driver_class_del_admin',
								driver_class_id:item.id
							},
							callback:(data)=>{

								//刷新
								this.get_page_data();
							}
						});
					}
				});
			},

			//打开添加页面
			open_add_view(){
				
				//打开弹出层
				this.add_form_is_show++;
			},
			close_add_view(){//关闭添加页面

				//关闭弹出层
				this.add_form_is_show=0;

				//刷新数据
				this.get_page_data();
			},

			//搜索
			page_ser(){
				this.get_page_data()
			},

			//获取页面数据
			get_page_data(){

				//加载中...
				if(this.list.loading){
					return;
				}

				//置为加载中
				this.list.loading=true;

				//读取数据
				this.$my.net.req({
					data:{
						mod:'truck_driver',
						ctr:'driver_class_list',
						...this.form
					},
					callback:(data)=>{

						//加载完毕
						this.list.loading=false

						//总数
						this.list.total=data.max

						//预处理
						for(let item of data.list){

							//创建时间
							item.creat_time_text=this.$my.other.totime(item.creat_time);
						}

						//渲染
						this.list.data=data.list
					}
				})
			},
		}
	}
</script>

<style lang="scss" scoped>

	//用户修改/添加表单的额外权限菜单部分
	.menu_list{
		background-color:#eee;
		height: 500px;
		overflow: auto;
		padding: 0 20px;
		margin-top: 10px;
		.children_list{
			margin-left: 20px;
		}
	}

	//其他
	.page{
		background-color: #fff;
		padding: 20px;
		border-radius: 10px;
		height: calc(100% - 40px);
		overflow: auto;
	}
	.ser_form{
		display: flex;
		align-items: flex-end;
		flex-wrap: wrap;
	}
	.tab_height{
		height:calc(100% - 90px);
	}
	.el_form_item{
		width: 25%;
	}
	.el_input{
		width:94%
	}
	.btn_left{
		margin: 0;
	}
</style>